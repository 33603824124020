.heroContainer {
  width: 100%;
  background-color: #fff;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 7rem;
}

.heroContent {
  width: 80%;
  margin: 0 auto;
}

.heroContent h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  font-size: calc(1.325rem + 0.9vw);
  margin-bottom: 0.5rem;
  color: #212529;
}

.heroContent p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  width: 40%;
}

@media screen and (max-width: 1024px) {
  .heroContent {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .heroContainer {
    padding-top: 11rem;
  }

  .heroContent {
    width: 70%;
  }

  .heroContent p {
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .heroContent {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .heroContent {
    width: 90%;
  }
}
