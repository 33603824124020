.wrapper {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  backdrop-filter: blur(6px);
  border-top: 1px solid #cada3c;
}

.headerContainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-top: 2.2rem; */
  height: 12vh;
  align-items: center;
}

.headerContainer a {
  text-decoration: none;
  color: #000;
  font-family: "Lato", sans-serif;
  font-weight: 800;
  font-size: 1.8rem;
  width: 10%;
}

.headerContainer img {
  max-width: 100%;
}

.toggler {
  display: none;
}

.mobileNav {
  display: none;
}

.activeMobileNav {
  display: none;
}

@media screen and (max-width: 1024px) {
  .headerContainer {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .headerContainer {
    width: 70%;
    align-items: center;
  }

  .headerContainer a {
    width: 20%;
  }

  .toggler {
    display: block;
  }

  .toggler .btn {
    font-size: 1.8rem;
    cursor: pointer;
    outline: none;
  }

  .mobileNav {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  .activeMobileNav {
    display: block;
    width: 70%;
    margin: 0 auto;
    margin-top: 1.5rem;
    background-color: #8e9b1d;
    padding-bottom: 1.5rem;
    transition: 0.35s ease-in-out;
    box-shadow: 0px 10px 92px -28px rgba(0, 0, 0, 0.75);
  }

  .navUl {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  .navUl li {
    list-style: none;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .navUl li a {
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: #fff;
    padding: 8px;
    padding-right: 30px;
  }

  .span {
    border: 1px solid #000;
    margin-top: 10px;
  }
}

@media screen and (max-width: 880px) {
  .headerContainer {
    width: 80%;
  }

  .activeMobileNav {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .headerContainer {
    width: 90%;
  }

  .activeMobileNav {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .toggler {
    display: block;
  }

  .toggler .btn {
    font-size: 1.8rem;
  }

  .activeMobileNav {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    background-color: #fff;
  }

  .headerContainer a {
    width: 28%;
  }

  .headerContainer {
    height: 10vh;
  }
}
