.aboutContainer {
  width: 100%;
  background-color: #fff;
  padding-top: 15rem;
  padding-bottom: 0;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 6rem;
}

.aboutText {
  width: 50%;
}

.aboutText h1 {
  font-family: "Lato", sans-serif;
  font-size: calc(1.115rem + 1.5vw);
  margin-bottom: 1.5rem !important;
  font-weight: 600;
  line-height: 1.4;
  color: #212529;
}

.aboutText p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  margin-bottom: 1rem;
}

.aboutBlue {
  background-color: #16676a;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
  width: 48%;
}

.aboutBlue .quote {
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: calc(1.155rem + 3.9vw);
  color: #fff !important;
}

.aboutBlue p {
  font-size: 1.25rem;
  font-weight: 400;
  color: #fff;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  padding-top: 0.5rem;
  /* width: 100%; */
  font-family: "Lato", sans-serif;
  line-height: 1.8;
}

.aboutBlue h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  padding-top: 0.5rem;
  font-size: 1.25rem;
  color: #cada3c;
}
/* 
.aboutBlue > * {
  position: relative;
  z-index: 1;
}

.aboutBlue::after {
  content: "";
  border: solid 1px #273253;
  width: calc(100% - 20px);
  height: 100%;
  position: absolute;
  display: block;
  left: 0;
  bottom: -20px;
  z-index: 0;
} */

.aboutImg {
  width: 100%;
  background-image: url(../../../Asset/Images/teamImg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 80vh;
  margin-top: 6rem;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 90%;
    gap: 4rem;
  }

  .aboutImg {
    /* min-width: 100vw; */
  }
}

@media screen and (max-width: 990px) {
  .aboutContainer {
    padding-top: 11rem;
  }

  .wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .aboutText,
  .aboutBlue {
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .wrapper {
    width: 90%;
  }

  .aboutImg {
    min-height: 50vh;
  }
}

@media screen and (max-width: 425px) {
  .aboutBlue {
    padding-left: 1.5rem !important;
    padding-right: 1rem !important;
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important;
  }
}
