.heroWrapper {
  width: 100%;
  background-color: #fff;
  background-size: cover;
  margin-top: 95px;
}

.heroContainer {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 2rem;
}

.heroText {
  width: 50%;
  padding-top: 8rem;
  padding-bottom: 10rem;
}

.heroText .active h1 {
  opacity: 1;
  transform: translateX(0px);
  transition-delay: 150ms;
}

.heroText h1 {
  font-weight: 700;
  font-family: "Lato", sans-serif;
  font-size: 3.1rem;
  /* width: 68%; */
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.4;
  color: #000;
  /* position: relative; */
  animation: spin_words 5s infinite;
}
@keyframes spin_words {
  30% {
    transform: translateY(-15%);
  }
}

.heroContainer .lottie {
  width: 52%;
}

/* .heroText h1::after {
  content: "|";
  position: absolute;
  right: 0;
  color: #197474;
  background-color: #fff;
  width: 100%;
  animation: typing 3s steps(1) alternate infinite;
}
@keyframes typing {
  to {
    width: 0%;
  }
}
@keyframes line {
  50% {
    color: transparent;
  }
} */

.heroImg {
  background-image: url(../../../Asset/Images/heroImg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-blend-mode: multiply;
  height: 80vh;
  width: 100%;
  transition: all 0.5s;
}

/* .heroImg:hover {
  background-color: #197474;
  transition: all 0.5s;
} */

@media screen and (max-width: 1440px) {
  .heroWrapper {
    margin-top: 110px;
  }
}

@media screen and (max-width: 1330px) {
  .heroWrapper {
    margin-top: 97px;
  }
}

@media screen and (max-width: 1111px) {
  .heroContainer .lottie {
    display: none;
  }

  .heroContainer .heroText {
    width: 100%;
    padding-top: 8rem;
  }
}

@media screen and (max-width: 1091px) {
  .heroWrapper {
    margin-top: 81px;
  }
}

@media screen and (max-width: 1024px) {
  .heroWrapper {
    margin-top: 81px;
  }

  .heroContainer .heroText {
    width: 100%;
    padding-top: 7rem;
    padding-bottom: 8rem;
  }

  .heroContainer {
    width: 90%;
  }

  .heroText {
    width: 90%;
  }
  .heroText h1 {
    width: 89%;
    font-size: 2.8rem;
  }

  .heroText h2 {
    font-size: calc(1.205rem + 0.9vw);
  }
}

@media screen and (max-width: 990px) {
  .heroText h1 {
    font-size: 2.7rem;
  }

  .heroImg {
    min-height: 33vh;
  }

  .heroContainer {
    width: 70%;
  }
}

@media screen and (max-width: 880px) {
  .heroContainer {
    width: 80%;
  }

  .heroText h1 {
    font-size: 2.6rem;
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .heroContainer {
    width: 90%;
    padding-top: 1rem;
  }

  .heroText h1 {
    font-size: 2.5rem;
    width: 100%;
  }

  .heroImg {
    min-height: 38vh;
  }
}

@media screen and (max-width: 500px) {
  .heroWrapper {
    margin-top: 75px !important;
  }

  .heroText {
    padding-bottom: 5.5rem;
  }

  .heroText h1 {
    font-size: 2.3rem;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .heroText h1 {
    font-size: 2.2rem;
  }
}
