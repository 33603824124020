.container {
  width: 100%;
  background-color: #fff;
  background-size: cover;
  margin-bottom: 3rem;
  padding-bottom: 7.5rem;
  padding-top: 3rem;
}

.partContent,
.partContentTwo {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.partContentThree {
  display: flex;
  flex-direction: row;
  gap: 10.3rem;
  margin: 0 auto;
  width: 80%;
}

.partContent .invest,
.partContent .bank,
.partContent .const,
.partContentTwo .invest,
.partContentTwo .bank,
.partContentTwo .const,
.partContentThree .invest,
.partContentThree .bank {
  border: 1px solid #000;
  align-items: center;
  text-align: center;
  /* width: 40%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.partContent .invest {
  /* width: 90%; */
}

.invest {
  width: 24%;
}

.invest > img {
  width: 100%;
}

.bank,
.const {
  width: 25%;
}

.bank > img,
.const > img {
  width: 100%;
}

.partContentTwo,
.partContentThree {
  padding-top: 3rem;
}

@media screen and (max-width: 1024px) {
  .partContent,
  .partContentTwo {
    width: 90%;
  }

  .invest {
    width: 31%;
  }

  .invest > img {
    width: 100%;
  }

  .bank,
  .const {
    width: 31%;
  }
}

@media screen and (max-width: 990px) {
  .partContent,
  .partContentTwo,
  .partContentThree {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-top: 1rem;
    gap: 1rem;
  }

  .invest {
    width: 100%;
  }

  .invest > img {
    width: 40%;
  }

  .bank {
    width: 100%;
  }

  .const {
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .partContent,
  .partContentTwo,
  .partContentThree {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .partContent,
  .partContentTwo,
  .partContentThree {
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  .invest {
    width: 100%;
  }

  .invest > img {
    width: 60%;
  }
}
