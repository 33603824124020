.container {
  width: 100%;
  margin-top: 7.5rem;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.content {
  width: 90%;
  padding: 80px 60px;
  background-color: #16676a;
}

.content .quote {
  font-weight: 900;
  color: #fff;
  font-size: calc(1.575rem + 3.9vw);
  margin-bottom: 2rem;
}

.content .lead {
  color: #fff;
  font-size: 1.33rem;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 1.8;
  margin-bottom: 1.6rem;
  font-style: italic;
}

.content h5 {
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  color: #cada3c;
  font-family: "Lato", sans-serif;
  line-height: 1.8;
}

.wrapper .imgBox {
  width: 70%;
  padding: 80px 0 10px 0;
  background-image: linear-gradient(90deg, #16676a 52%, #fff 34%);
  /* margin-bottom: -80px;   */
  /* padding-top: 3rem; */
}

.borderLeft {
  padding-left: 20px;
  position: relative;
  /* margiin-top: 180px; */
}

.borderLeft img {
  max-width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

.borderLeft::after {
  content: "";
  border: solid 1px #f4e9da;
  width: calc(100% - 20px);
  height: 100%;
  position: absolute;
  display: block;
  left: 0;
  bottom: -20px;
  z-index: 0;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .container {
    margin-top: 3rem;
  }
  .wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .content {
    width: 100%;
    background-color: #16676a;
    padding: 50px 40px 50px 40px;
  }

  .wrapper .imgBox {
    width: 100%;
    padding: 0px;
    background-image: linear-gradient(90deg, #16676a 0%, #fff 0%);
  }

  .borderLeft {
    padding-left: 0px;
  }

  .borderLeft img {
    max-width: 100%;
    height: auto;
  }

  .borderLeft::after {
    display: none;
  }
}

@media screen and (max-width: 880px) {
  .wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .wrapper {
    width: 90%;
  }
}
