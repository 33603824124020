.navUl {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
}

.navUl li {
  list-style: none;
}

.navUl li a {
  color: #000;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 1.087rem;
  transition: 0.3s ease-in-out;
}

.navUl li a:hover {
  color: #cada3c;
}

.navUl button {
  background-color: #16676a;
  border: none;
  padding: 0.8rem 2.2rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  line-height: 1.8;
  font-size: 1.087rem;
  font-family: "Lato", sans-serif;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: 0.4s ease-in-out;
}

.navUl button:hover {
  background-color: #cada3c;
  box-shadow: 10px 10px 32px -28px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 1024px) {
  .navUl {
    gap: 2rem;
  }
}

@media screen and (max-width: 990px) {
  .navUl {
    display: none;
  }
}
