.container {
  width: 100%;
  margin-top: 7.5rem;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.content {
  width: 50%;
  padding: 80px 70px 80px 60px;
  background-color: #16676a;
}

.content h2 {
  color: #cada3c;
  margin-bottom: 1rem !important;
  font-size: calc(1.375rem + 1.5vw);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
}

.content .lead {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 1.8;
  margin-top: 2rem;
}

.content p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
}

.wrapper .imgBox {
  width: 40%;
  padding: 80px 0 100px;
  background-image: linear-gradient(90deg, #16676a 52%, #fff 34%);
}

.borderLeft {
  padding-left: 20px;
  position: relative;
}

.borderLeft img {
  max-width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .container {
    margin-top: 3rem;
  }
  .wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .content {
    width: 100%;
    background-color: #16676a;
    padding: 50px 40px 50px 40px;
  }

  .wrapper .imgBox {
    width: 100%;
    padding: 0px;
    background-image: linear-gradient(90deg, #16676a 0%, #fff 0%);
  }

  .borderLeft {
    padding-left: 0px;
  }

  .borderLeft img {
    max-width: 100%;
    height: auto;
  }

  .borderLeft::after {
    display: none;
  }
}

@media screen and (max-width: 880px) {
  .wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .content .lead {
    font-size: 1rem;
  }
}
