.container {
  width: 100%;
  padding-top: 15rem;
  padding-bottom: 4rem;
  /* background: linear-gradient(180deg, #f4e9da 75%, #fff 75%); */
  background-color: #fff;
}

.contentContainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.wrapper {
  width: 50%;
}

.wrapper h1 {
  font-size: calc(1.29rem + 1.33vw);
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  color: #000;
}

.wrapper span {
  font-size: calc(1.29rem + 1.33vw);
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  color: #cada3c;
}

.wrapper img {
  width: 60%;
  display: flex;
  overflow-clip-margin: content-box;
  overflow: clip;
  margin-top: 4rem;
  margin-left: 10rem;
}

.teamImg {
  width: 50%;
}

.teamImg img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .contentContainer {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .container {
    padding-top: 11rem;
  }

  .contentContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    width: 100%;
  }

  .wrapper h1,
  .wrapper p {
    width: 100%;
  }

  .wrapper p {
    margin-top: 20px;
  }

  .wrapper img {
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;
  }

  .teamImg {
    width: 100%;
    margin-top: 4.3rem;
  }
}

@media screen and (max-width: 880px) {
  .contentContainer {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .contentContainer {
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  .wrapper h1 {
    width: 61%;
  }
}
