.tunde_Container {
  margin-top: 10rem;
}

.tunde_Wrapper {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 10px 10px 62px -28px rgba(0, 0, 0, 0.75);
  padding: 4rem;
  border-radius: 10px;
}

.tunde_Wrapper img {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.tunde_Wrapper h3 {
  font-size: 1.5rem;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 1rem;
  font-style: italic;
  color: #cada3c;
}

.tunde_Wrapper a {
  color: #cada3c;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  font-size: 1.1rem;
  transition: 0.3s all ease-in-out;
}

.tunde_Wrapper a:hover {
  color: #000;
}

.tunde_Wrapper h1 {
  color: #000;
  font-size: calc(1.325rem + 0.9vw);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 1rem;
}

.tunde_Wrapper p {
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.8;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #212529;
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
}

.tunde_Wrapper .tunde_Link {
  color: #000;
  text-decoration: none;
  font-size: 0.894rem;
  border: 1px solid #cada3c;
  padding: 10px 20px 10px 20px;
  line-height: 4;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .tunde_Wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .tunde_Wrapper {
    padding: 2rem;
  }

  .tunde_Wrapper p {
    font-size: 1rem;
  }

  .tunde_Wrapper img {
    width: 100%;
  }
}
