.footerContainer {
  width: 100%;
  background: #16676a;
  color: #fff;
  padding-top: 6.25rem;
  margin-top: 7rem;
  padding-bottom: 6rem;
  /* padding-bottom: 2rem; */
}

.footerContent {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 7rem;
  justify-content: space-between;
  /* gap: 2.2rem; */
}

.sectOne {
  width: 35%;
}

.sectOne h4 {
  font-size: calc(1.275rem + 0.1vw);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  width: 100%;
}

.sectOne p {
  font-size: calc(1.275rem + 0.1vw);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  width: 100%;
}

.sectOne .trans {
  text-decoration: none;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.trans:hover {
  opacity: 0.5;
  /* color: black; */
}

.sectOne .socials {
  display: flex;
  flex-direction: row;
  gap: 1.7rem;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 3.3rem;
  align-items: center;
}

.socials a {
  text-decoration: none;
  border: 1px solid #fff;
  padding-top: 0.4rem;
  padding-bottom: 0.1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: #fff;
  font-weight: 500;
  line-height: 2;
}

.sectOne li {
  list-style: none;
  margin-top: 1rem;
}

.sectOne li a {
  color: #fff;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  padding: 0.3846153846rem 0;
  font-weight: 500;
  line-height: 2;
}

.navUl {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* align-items: center; */
}

.navUl li {
  list-style: none;
}

.navUl li a {
  color: #fff;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 1.087rem;
  transition: 0.3s ease-in-out;
}

.navUl li a:hover {
  opacity: 0.5;
}

hr {
  color: #f4e9da;
  opacity: 0.1;
  /* padding-bottom: 8rem; */
}

@media screen and (max-width: 1024px) {
  .footerContent {
    width: 90%;
  }

  .footerContent {
    gap: 3rem;
  }

  .sectOne {
    width: 40%;
  }

  .navUl {
    gap: 1.2rem;
  }
}

@media screen and (max-width: 990px) {
  .footerContent {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 880px) {
  .footerContent {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .footerContent {
    width: 90%;
  }

  .sectOne {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .footerContainer {
    padding-bottom: 4.5rem;
  }

  .navUl {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }
}
