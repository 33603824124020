.mainContainer {
  margin-top: 10rem;
  width: 100%;
}

.wrapperContainer {
  width: 80%;
  margin: 0 auto;
}

.wrapperContainer h1 {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
}

.wrapperContainer h2 {
  font-size: 1.3rem;
  margin-top: 25px;
  margin-bottom: 10px;
}

.wrapperContainer p {
  line-height: 2;
  font-size: 1.1rem;
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  .wrapperContainer {
    width: 90%;
  }
}
