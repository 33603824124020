.container {
  width: 100%;
  margin-top: 4rem;
}

.content {
  width: 80%;
  margin: 0 auto;
}

.flexOne {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2.8rem;
  margin-bottom: 3rem;
}

.flexOne img {
  max-width: 100%;
}

.firstWrap {
  width: 90%;
}

.imgWrapperOne,
.imgWrapperThree,
.imgWrapperFour,
.imgWrapperFive,
.imgWrapperSix,
.imgWrapperSeven {
  padding-bottom: 6rem;
  border: 1px solid #f4e9da !important;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  margin-top: -5px;
}

.imgWrapperSix {
  padding-bottom: 7rem;
}

.flexOne .firstImg {
  width: 100%;
}

.lastWrap {
  width: 30%;
}

.lastImg {
  width: 100%;
}

.imgWrapper,
.imgWrapperLast {
  border: 1px solid #f4e9da !important;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: -5px;
}

.imgWrapperLast {
  padding-bottom: 14rem;
}

.imgWrapper h5,
.imgWrapperOne h5,
.imgWrapperThree h5,
.imgWrapperFour h5,
.imgWrapperFive h5,
.imgWrapperSix h5,
.imgWrapperSeven h5,
.imgWrapperLast h5 {
  color: #212529;
  font-size: 1.25rem;
  margin-bottom: 15px;
  margin-top: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
}

.imgWrapper span,
.imgWrapperOne span,
.imgWrapperThree span,
.imgWrapperFour span,
.imgWrapperFive span,
.imgWrapperSix span,
.imgWrapperSeven span,
.imgWrapperLast span {
  color: #6c757d;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
}

.imgWrapper p,
.imgWrapperOne p,
.imgWrapperThree p,
.imgWrapperFour p,
.imgWrapperFive p,
.imgWrapperSix p,
.imgWrapperSeven p,
.imgWrapperLast p {
  margin-bottom: 1rem;
  font-family: "Lato", sans-serif;
  font-size: 1.019rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
}

.imgWrapper a,
.imgWrapperOne a,
.imgWrapperThree a,
.imgWrapperFour a,
.imgWrapperFive a,
.imgWrapperSix a,
.imgWrapperSeven a,
.imgWrapperLast a {
  color: #000;
  text-decoration: none;
  font-size: 0.894rem;
  border: 1px solid #cada3c;
  padding: 10px 20px 10px 20px;
  line-height: 4;
}

.imgWrapperLast a {
  font-family: "lato", sans-serif;
}

.imgWrapper a:hover,
.imgWrapperOne a:hover,
.imgWrapperThree a:hover,
.imgWrapperFour a:hover,
.imgWrapperFive a:hover,
.imgWrapperSix a:hover,
.imgWrapperSeven a:hover,
.imgWrapperLast a:hover {
  color: #000;
  text-decoration: underline;
}

.imgWrapper .socials,
.imgWrapperOne .socials,
.imgWrapperThree .socials,
.imgWrapperFour .socials,
.imgWrapperFive .socials,
.imgWrapperSix .socials,
.imgWrapperSeven .socials,
.imgWrapperLast .socials {
  display: flex;
  flex-direction: row;
  gap: 1.7rem;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 1.1rem;
  align-items: center;
}

.socials a {
  text-decoration: none;
  border: 1px solid blueviolet;
  padding-top: 0.4rem;
  padding-bottom: 0.1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: #000;
  font-weight: 500;
  line-height: 2;
  transition: 3s ease-in-out;
}

.socials a:hover {
  background-color: blueviolet;
  border: 1px solid blueviolet;
  color: #fff;
}

.last {
  width: 32%;
}

@media screen and (max-width: 1024px) {
  .content {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .container {
    margin-top: 2rem;
  }
  .content {
    width: 70%;
    margin: 0 auto;
  }

  .flexOne {
    display: flex;
    flex-direction: column;
  }

  .flexOne img {
    width: 100%;
  }

  .last {
    width: 100%;
  }

  .lastWrap {
    width: 100%;
    padding-bottom: 0rem;
  }

  .lastWrap .lastImg {
    width: 100%;
  }

  .firstWrap {
    width: 100%;
  }

  .imgWrapperOne,
  .imgWrapperThree,
  .imgWrapperFour,
  .imgWrapperFive,
  .imgWrapperSix,
  .imgWrapperSeven,
  .imgWrapperLast {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 880px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .content {
    width: 90%;
  }
}
