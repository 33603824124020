.container {
  width: 100%;
  background-color: #fff;
  background-size: cover;
  padding-top: 15rem;
  padding-bottom: 5rem;
}

.heroContent {
  width: 80%;
  margin: 0 auto;
}

.heroContent h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  color: #212529;
  font-size: calc(1.315rem + 1.2vw);
}

.heroContent p {
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .heroContent {
    width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .container {
    padding-top: 11rem;
  }

  .heroContent {
    width: 70%;
  }

  .heroContent p,
  .heroContent h1 {
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .heroContent {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .heroContent {
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  .heroContent h1 {
    width: 80%;
  }
}
