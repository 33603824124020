.valueContainer {
  width: 100%;
  background-color: #d5daaf;
  background-size: cover;
  margin-top: 7rem;
  padding-top: 8rem;
  padding-bottom: 5rem;
}

.valueContent {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;
  align-items: center;
}

.borderRight {
  flex: 0 0 auto;
  width: 50%;
  /* margiin-top: 180px; */
}

.borderRight {
  padding-right: 20px;
  position: relative;
}

.borderRight img {
  position: relative;
  z-index: 1;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.borderRight::after {
  content: "";
  border: solid 1px #273253;
  width: calc(100% - 20px);
  height: 100%;
  position: absolute;
  display: block;
  right: 0;
  bottom: -20px;
  z-index: 0;
}

.valueText h2 {
  font-weight: 700;
  line-height: 1.4;
  font-family: "Lato", sans-serif;
  /* margin-bottom: 1rem; */
  color: #212529;
  font-size: 2.1rem;
}

.valueText p {
  margin-top: 0;
  /* margin-bottom: 1rem; */
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.8;
  color: #212529;
  font-family: "Lato", sans-serif;
  width: 110%;
}

.valueText h5 {
  font-size: 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .valueContent {
    width: 90%;
  }

  .borderRight::after {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .valueContent {
    width: 70%;
  }

  .valueContainer {
    padding-top: 4rem;
  }

  .valueContent {
    display: flex;
    flex-direction: column;
  }

  .borderRight {
    width: 100%;
    margin: 0 auto;
    padding-right: 0px;
  }

  .borderRight img {
    width: 100%;
    margin: 0 auto;
  }

  .valueText h2,
  .valueText p {
    width: 100%;
  }

  .valueText h2 {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 880px) {
  .valueContent {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .valueContent {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .valueText h2 {
    font-size: 1.6rem;
    margin-top: -1rem;
  }

  .valueText p {
    font-size: 1rem;
  }
}
