.serviceContainer {
  width: 100%;
  margin-top: 6rem;
}

.serviceText {
  width: 80%;
  margin: 0 auto;
}

.serviceText h5 {
  color: #cada3c;
  font-size: 1.55rem;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.serviceText h3 {
  font-size: calc(1.3rem + 0.6vw);
  font-family: "Lato", sans-serif;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.4;
  width: 40%;
  margin-bottom: 2rem;
  color: #212529;
}

.serviceFlex,
.serviceFlexSecond {
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.serviceFlexSecond {
  gap: 3rem;
}

.serviceFlex .marketingIcon,
.serviceFlexSecond .devIcon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fbbe4b;
  font-size: calc(1.375rem + 1.5vw);
}

.serviceFlex h4,
.serviceFlexSecond h4 {
  margin-bottom: 1.5rem !important;
  font-size: calc(1.275rem + 0.3vw);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 1.4;
  color: #212529;
  -webkit-text-size-adjust: 100%;
  margin-top: 1rem;
}

.serviceFlex p,
.serviceFlexSecond p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.8;
  color: #212529;
  font-family: "Lato", sans-serif;
  width: 90%;
}

.serviceFlexSecond .webIcon {
  color: #ed5c4d;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: calc(1.375rem + 1.5vw);
}

.serviceFlex .brandIcon {
  color: #57b5ed;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: calc(1.375rem + 1.5vw);
}

.serviceFlex .fold {
  margin-top: 1rem;
}

@media screen and (max-width: 1024px) {
  .serviceText {
    width: 90%;
  }

  .serviceFlexSecond {
    gap: 2rem;
  }
}

@media screen and (max-width: 990px) {
  .serviceText {
    width: 70%;
  }

  .serviceFlex,
  .serviceFlexSecond {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }

  .serviceFlexSecond {
    gap: 0rem;
  }

  .serviceText h3 {
    width: 100%;
  }

  .serviceFlex p,
  .serviceFlexSecond p {
    margin-bottom: 1.8rem;
  }

  .serviceFlexSecond {
    margin-top: -4rem;
  }

  .serviceFlex .fold {
    margin-top: 0rem;
  }
}

@media screen and (max-width: 880px) {
  .serviceText {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .serviceText {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .serviceContainer {
    margin-top: 4rem;
  }

  .serviceFlexSecond {
    margin-bottom: 3rem;
  }

  .serviceFlex p,
  .serviceFlexSecond p {
    font-size: 1rem;
  }
}
